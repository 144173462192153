<template>
  <v-container>
    <v-row v-if="!showSuccess">
      <v-col col="12" class="tw-py-0">
        <h3
          class="tw-text-left tw-font-figtree tw-text-22 tw-text-dark-green tw-font-semibold"
        >
          How can we improve?
        </h3>
        <p class="tw-text-left tw-text-light-grey tw-mb-11">
          We want to build a product that you love. Let us know if there are any
          bugs we need to address or features you’d like to see.
        </p>
      </v-col>
    </v-row>
    <v-row v-if="!showSuccess">
      <v-col>
        <validation-observer ref="observer" v-slot="{ invalid }">
          <v-form @submit.prevent="save">
            <v-select
              outlined
              :items="categoryOptions"
              v-model="category"
              item-color="secondary"
              color="secondary"
              background-color="#fafafa"
            />
            <validation-provider v-slot="{ errors }" rules="required|max:500">
              <j-textarea
                v-model="text"
                outlined
                :error-messages="errors"
                background-color="#fafafa"
                label="Message*"
              />
            </validation-provider>
            <v-btn
              type="button"
              x-large
              rounded
              block
              depressed
              :disabled="!text || text.length < 5"
              class="j-btn tw-bg-chartreuse tw-mt-4 tw-tracking-normal"
              @click="save()"
              >Submit
            </v-btn>
          </v-form>
          <span class="tw-hidden">{{ invalid }}</span>
        </validation-observer>
      </v-col>
    </v-row>
    <v-row v-if="showSuccess">
      <img
        src="@/assets/svgs/support/thank-you.svg"
        alt="Thank you"
        class="tw-m-auto"
      />
      <h3
        class="tw-text-center tw-font-figtree tw-text-22 tw-text-dark-green tw-font-semibold tw-w-full tw-mb-2"
      >
        Thank you!
      </h3>
      <p class="tw-text-center tw-text-light-grey tw-mb-11">
        We take feedback seriously and will respond to your request as soon as
        possible. If you have any questions, please reach us at <br /><a
          target="_blank"
          class="tw-font-bold tw-text-light-grey"
          href="mailto:'support@letsjetty.com'"
          >support@letsjetty.com</a
        >.
      </p>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "SupportBugReport",
  computed: {
    ...mapGetters({
      user: "auth/user"
    })
  },
  components: {},
  data() {
    return {
      categoryOptions: [
        { text: "Feedback", value: "FEEDBACK" },
        { text: "Bug", value: "BUG" },
        { text: "Help", value: "HELP" },
        { text: "My Purchases", value: "PAYMENT" },
        { text: "Delete Account", value: "DELETEACCOUNT" },
      ],
      category: "FEEDBACK",
      text: null,
      showSuccess: false
    };
  },
  methods: {
    save() {
      let data = {
        category: this.category,
        userId: this.user.id,
        userFullName: this.user.fullName,
        userEmail: this.user.email,
        text: this.text,
        appVersion: process.env.VUE_APP_VERSION,
        device: null
      };
      this.$store
        .dispatch("support/create", [data])
        .then(() => {
          this.showSuccess = true;
          this.$store.commit("meta/SET_NAVIGATION_BUTTON", {
            button: false
          });
        })
        .catch((err) => {
          console.error("error", err);
          this.$store.dispatch("meta/showGlobalAlert", {
            type: "error",
            text: "Error sending bug report. Please try again.",
            timeout: 2000
          });
        });
    }
  },
  mounted() {
    this.$store.commit("meta/setHeader", {
      defaultHeaderDisabled: false,
      pageTitle: "Support",
      bgColor: "#E6FFA1",
      fontColor: "#203848",
      tagline: null,
      iconOne: null,
      iconTwo: null,
      showBackButton: true
    });
    // this.$store.commit("meta/SET_NAVIGATION_BUTTON", {
    //   button: true,
    //   text: "Submit",
    //   isDisabled: true,
    //   method: this.save,
    //   bgColor: "tw-bg-chartreuse",
    // });
  }
};
</script>
