<template>
  <div class="tw-mb-10">
    <v-tabs v-model="tab" fixed-tabs color="deep-purple-accent-4">
      <v-tab value="'NOTIFICATIONS'" v-ripple="false">NOTIFICATIONS</v-tab>
      <v-tab value="'GROUPCHAT'" v-ripple="false" disabled>
        GROUP CHAT
        <span
          class="tw-absolute tw-font-extrabold tw-tracking-normal tw-text-xxxxs tw-text-j-pink tw--mt-8 tw--ml-14"
          >COMING SOON!
        </span>
      </v-tab>
    </v-tabs>
    <v-window v-model="tab" class="tw-mt-2">
      <v-window-item>
        <v-container fluid v-if="notifications.length > 0">
          <v-row
            class="tw-py-2 tw-border-b-2 tw-relative"
            v-for="n in notifications"
            :key="n.id"
          >
            <template>
              <v-col
                cols="12"
                class="tw-flex tw-items-center tw-cursor-pointer"
                @click="takeMeToNotification(n)"
                :id="n.id"
              >
                <UserAvatar
                  :user="n.author"
                  size="56"
                  class="tw-mr-4"
                  v-if="n.author"
                />
                <img
                  src="@/assets/lets-jetty-mark.jpeg"
                  alt="Default"
                  class="tw-mr-4 tw-bg-chartreuse tw-rounded-full tw-w-14 tw-h-14"
                  v-else
                />
                <div class="tw-w-3/4">
                  <template v-if="n.tripId && trips[n.tripId]">
                    <p
                      class="tw-text-left tw-uppercase tw-text-xs tw-mb-2 fit-content tw-bg-lightest-grey tw-px-3 tw-rounded-md"
                      :class="{ 'tw-font-bold': !n.isRead }"
                    >
                      {{ trips[n.tripId].title }}
                    </p>
                  </template>
                  <p
                    class="tw-text-left tw-text-sm tw-font-light tw-mb-2"
                    :class="{ 'tw-font-bold': !n.isRead }"
                  >
                    {{ n.title }}
                  </p>
                  <p
                    class="tw-text-left tw-text-xxs tw-uppercase tw-mb-0"
                    :class="{ 'tw-font-semibold': !n.isRead }"
                  >
                    {{ getFormattedTimeAgo(n.createdAt) }}
                  </p>
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  class="tw-absolute tw-right-0"
                  v-if="!n.isRead"
                >
                  <circle
                    cx="5.62122"
                    cy="5.62122"
                    r="5.62122"
                    fill="#E6FFA1"
                  />
                </svg>
              </v-col>
            </template>
          </v-row>
        </v-container>
        <v-container fluid v-else>
          <p class="tw-mt-12">No new activities</p>
        </v-container>
      </v-window-item>
      <v-window-item>
        <v-container fluid>
          <v-row>
            <v-col cols="12"> Group Chat</v-col>
          </v-row>
        </v-container>
      </v-window-item>
    </v-window>
  </div>
</template>
<script>
import UserAvatar from "@/components/shared/UserAvatar.vue";
import { FormattedDateRange } from "@/mixins/FormattedDateRange.js";
export default {
  name: "ActivityNotificationsView",
  mixins: [FormattedDateRange],
  components: {
    UserAvatar
  },
  data() {
    return {
      notifications: [],
      trips: {},
      tab: "NOTIFICATIONS"
    };
  },
  methods: {
    async setNotificationAsSeen(n) {
      this.$store.commit("meta/setReadNotifications", true);
      await this.$store.dispatch("notification/patch", [
        n.id,
        {
          isSeen: true
        }
      ]);
    },
    async takeMeToNotification(n) {
      await this.$store.dispatch("notification/patch", [
        n.id,
        {
          isRead: true
        }
      ]);

      let slugArray = n.slug.split("-");
      if (slugArray.includes("destination")) {
        this.$router.push({
          name: "DestinationsRoute",
          params: { id: n.tripId }
        });
      } else if (slugArray.includes("rsvp")) {
        this.$router.push({
          name: "CrewListRoute",
          params: { id: n.tripId }
        });
      } else if (slugArray.includes("survey")) {
        this.$router.push({
          name: "SurveyListRoute",
          params: { id: n.tripId }
        });
      } else if (slugArray.includes("dates")) {
        this.$router.push({
          name: "DatesListRoute",
          params: { id: n.tripId }
        });
      } else if (slugArray.includes("activity")) {
        this.$router.push({
          name: "ActivitiesRoute",
          params: { id: n.tripId }
        });
      } else if (slugArray.includes("itinerary")) {
        this.$router.push({
          name: "TripItineraryList",
          params: { id: n.tripId }
        });
      } else if (
        slugArray.includes("place") ||
        slugArray.includes("accommodation")
      ) {
        this.$router.push({
          name: "AccommodationList",
          params: { id: n.tripId }
        });
      } else if (n.slug === "premium-order-confirmation") {
        this.$router.push({
          name: 'ProfileHome'
        });
      } else {
        this.$router.push({
          name: "TripViewRoute",
          params: { id: n.tripId }
        });
      }
    },
    async fetchData() {
      try {
        for (let notification of this.notifications) {
          if (notification.tripId) {
            const trip = await this.$store.dispatch(
              "trip/get",
              notification.tripId
            );
            this.$set(this.trips, notification.tripId, trip);
          }
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    }
  },
  async beforeMount() {
    const unseenNotifications = await this.$store.dispatch(
      "notification/find",
      {
        query: {
          isInApp: true,
          isSeen: false
        }
      }
    );

    //set all as seen
    if (unseenNotifications && unseenNotifications.data) {
      unseenNotifications.data.forEach((n) => {
        this.setNotificationAsSeen(n);
      });
    }

    const notificationsRes = await this.$store.dispatch("notification/find", {
      query: {
        isInApp: true,
        $sort: {
          createdAt: -1,
        },
        $limit: 20,
      }
    });

    if (notificationsRes && notificationsRes.data) {
      this.notifications = notificationsRes.data.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      await this.fetchData();
    }
  },
  mounted() {
    this.$store.commit("meta/setHeader", {
      defaultHeaderDisabled: false,
      pageTitle: "Activity",
      bgColor: "#6A4AE8",
      fontColor: "#ffffff",
      tagline: null,
      iconOne: null,
      iconTwo: null,
      tripHubButton: false,
      showBackButton: true
    });
  }
};
</script>
<style lang="scss">
.v-tab--active {
  color: #3eb9ff !important;
  font-weight: 700;
  border-bottom: 1px solid black !important;

  &:hover:before,
  &:active:before,
  &:focus:before {
    background-color: transparent;
  }
}

.fit-content {
  width: fit-content;
}
</style>
